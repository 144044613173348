import useFetch from "../../hooks/useFetch"
import FeaturedCleaningCategoriesCard from "../cards/featuredCleaningCategoriesCard/FeaturedCleaningCategoriesCard"
import "./featuredCleaningCategories.css"

const FeaturedCleaningCategories = () => {
  const { data, loading, error } = useFetch(`/categories`)

  return (
    <div className="featuredCleaningCategories">
        <div className="featuredCleaningCategoriesContainer">
            <div className="featuredCleaningCategoriesHeader">
                <span className="title">The services we <br />provide for <span className="s1">our customers</span></span>
            </div> 
            <div className="featuredCleaningCategoriesBody">
              {loading? <>
                <p>Loading</p>
              </>:error?<>
                <p>An error occured</p>
              </>:data.map((category, index) => (
                <FeaturedCleaningCategoriesCard cleaningCategory={category} key={index} />
              ))}
            </div>
        </div>
    </div>
  )
}

export default FeaturedCleaningCategories