import { useNavigate } from "react-router-dom"
import "./footer.css"
import { useContext } from "react";
import { SearchContext } from "../../contexts/SearchContext";
import { citiesList } from "../../utils/citiesSource";
import useFetch from "../../hooks/useFetch";

const Footer = () => {
  const navigate = useNavigate()
  const { dispatch }  = useContext(SearchContext);
  const { data, loading, error } = useFetch(`/categories`)

  const handleClick = (place, cleaning_type) => {
    dispatch({ type: "NEW_SEARCH", payload: { place, cleaning_type } });
    navigate("/services");
  }

  return (
    <div className="footer">
        <div className="footerContainer">
            <div className="footerContainerDetails">
                <div className="companyDetails">
                    <div className="footerTitle">
                        <h1>Elation <span>Cleaners</span></h1>
                    </div>
                    <div className="footerDesc">
                        <p>For professional and reliable cleaning <br />
                        solutions for homes and businesses, ensuring <br />
                        a clean and hygienic environment.</p>
                    </div>
                    <div className="socials">
                        <span className="socialsTitle">Follow us on</span>
                        <div className="socialMediaItems">
                            <span>
                                <i className='bx bxl-facebook-circle' ></i>
                            </span>
                            <span>
                                <i className='bx bxl-twitter' ></i>
                            </span>
                            <span>
                                <i className='bx bxl-instagram' ></i>
                            </span>
                            <span>
                                <i className='bx bxl-pinterest' ></i>
                            </span>
                            <span>
                                <i className='bx bxl-youtube' ></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="servicesDetails">
                    <span className="servicesTitle">Services</span>
                    <ul>
                        {data?.map((category, index) => (
                            <li key={index} onClick={() => handleClick("", category._id)}>{category.category} <sup><i class='bx bxs-right-top-arrow-circle'></i></sup></li>
                        ))}
                    </ul>
                </div>
                <div className="citiesDetails">
                    <span className="citiesTitle">Cities</span>
                    <ul>
                        {citiesList.map((city, index) => (
                            <li key={index} onClick={() => handleClick(city.name, "")}>
                                <i class='bx bxs-chevrons-right'></i>
                                <span className="cityFooter">
                                {city.name}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="shortLinks">
                    <span className="shortLinksTitle">Useful Links</span>
                    <ul>
                        <li onClick={() => navigate('/about')}>About Us</li>
                        <li onClick={() => navigate('/contact')}>Contact Us</li>
                        <div className="searchInput">
                            <input type="text" placeholder="Enter email" />
                            <span className="searchInputBtn">Subscribe</span>
                        </div>
                    </ul>
                </div>
            </div>
            <div className="footerSeparator"></div>
            <div className="footerDiv">
                <div className="footerDivCopyright">
                    <p> Copyright © Elation Cleaners • All Rights Reserved.</p>
                </div>
                <div className="footerDivItems">
                    <ul>
                        <li className="item-footer">Legal Notice</li>
                        <li>•</li>
                        <li className="item-footer">Privacy Policy</li>
                        <li>•</li>
                        <li className="item-footer">Terms & Conditions</li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Footer