import FeaturedCityCard from "../cards/featuredCityCard/FeaturedCityCard"
import "./featuredCity.css"
import useFetch from "../../hooks/useFetch"
import { citiesList } from "../../utils/citiesSource"
import { useEffect, useState } from "react"

const FeaturedCity = () => {
  
  const [featuredCityIndex, setFeaturedCityIndex] = useState({min: 0, max: 5});    
  const [cities, setCities] = useState([]);
  const { data, loading, error } = useFetch("/services/countByCity?cities=nairobi,kisumu,mombasa,nakuru,machakos,eldoret,kiambu")  
    
  const handlePrev = () => {
    if(featuredCityIndex.min > 0){
        setFeaturedCityIndex((prev) => ({min: (prev.min - 1), max: (prev.max - 1)}))
    }
  }

  const handleNext = () => {
    if(featuredCityIndex.max >= 5 && featuredCityIndex.max < data.length){
        setFeaturedCityIndex((prev) => ({min: (prev.min + 1), max: (prev.max + 1)}))
    }
  } 

  useEffect(() => {
    const newList = citiesList.map((city, index) => ({
        ...city,
        "count": data[index]
    }))
        setCities(newList)
  }, [data])

  console.log(cities)

  return (
    <div className='featuredCity'>
        <div className="featuredCityContainer">
            <div className="featuredCityHeader">
                <div className="featureCityHeaderTitle">
                    <span className="title">Cities</span>
                </div>
                <div className="navItems">
                    <span onClick={data.length > 0?(e) => handlePrev():null}><i class='bx bx-chevron-left' ></i></span>
                    <span onClick={data.length > 0?(e) => handleNext():null}><i class='bx bx-chevron-right' ></i></span>
                </div>
            </div>
            <div className="featuredCityBody">
                {!loading && cities.slice(featuredCityIndex.min, featuredCityIndex.max).map((city, index) => (
                    <FeaturedCityCard city={city} key={index} />
                ))}
            </div>
        </div>
    </div>
  )
}

export default FeaturedCity