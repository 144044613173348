import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar"
import "./dashboard.css"
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Footer from "../../components/footer/Footer";
import DashboardProfile from "../../components/dashboardProfile/DashboardProfile";
import DashboardBooking from "../../components/dashboardBooking/DashboardBooking";

const Dashboard = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const  { user } = useContext(AuthContext)
  const [activeItem, setActiveItem] = useState(location.state?.item || { mainTab : 1, subTab: 1 });

  const handleTabs = (main, sub) => {
    setActiveItem((prev) => ({...prev, ['mainTab']: main, ['subTab']: sub}))
  }

  useEffect(() => {
    if(!user){
      navigate("/")
    }
  }, [user]);

  return (
    <div className="dashboard">
        <Navbar type={'dashbaord'} />
        <div className="dashboardContainer">
            <div className="dashboardContainerCard">
                <div className="dashboardHeader">
                    <span 
                        className={activeItem.mainTab == 1?"dashboardHeaderItem active":"dashboardHeaderItem"}
                        onClick={(e) => handleTabs(1, 1)}
                    >Account</span>
                    <span 
                        className={activeItem.mainTab == 2?"dashboardHeaderItem active":"dashboardHeaderItem"}
                        onClick={(e) =>  handleTabs(2, 1)}
                    >Favorite</span>
                    <span 
                        className={activeItem.mainTab == 3?"dashboardHeaderItem active":"dashboardHeaderItem"}
                        onClick={(e) =>  handleTabs(3, 1)}
                    >Bookings</span>
                </div>
                <div className="dashboardBody">
                    <DashboardProfile activeItem={activeItem} />
                    <DashboardBooking activeItem={activeItem} />
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Dashboard