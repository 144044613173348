import Footer from "../../components/footer/Footer"
import Navbar from "../../components/navbar/Navbar"
import "./about.css"

const About = () => {
  return (
    <div className="about">
        <Navbar type={'about'} />
        <div className="aboutContainer">
            <section className="about-us">
                <div className="about-usContainer">
                    <div className="picContainer">
                        <img src="../images/about.png" className="pic" />
                    </div>
                    <div className="text">
                        <h2>About Us</h2>
                        <h5>Elation <span>Cleaners</span></h5>
                        <p>          
                            Elation Cleaners is a premier cleaning services website that aims to provide 
                            exceptional cleaning solutions for both residential and commercial spaces. 
                            Our website is your gateway to a world of spotless, hygienic, and organized environments.
                            <br />
                            <br />
                            Our comprehensive range of services covers every aspect of cleaning, ensuring 
                            that your space is not only clean but also free from allergens, dust, and pollutants. 
                            Whether you need regular house cleaning, deep cleaning, office maintenance, or specialized services, 
                            Elation Cleaners has you covered.
                        </p>
                        <div className="data">
                            <a href="./contact" className="hire">Contact Us</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer />
    </div>
  )
}

export default About