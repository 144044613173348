import { useState } from "react"
import Footer from "../../components/footer/Footer"
import Navbar from "../../components/navbar/Navbar"
import "./contact.css"
import axios from "axios"
import { hasValues } from "../../utils/helperFunctions"

const Contact = () => {
  const [contactDetails, setContactDetails] = useState({
    name: '',
    email: '',
    message: ''
  }) 
  const [contact, setContact] = useState({ loading: false, error: null })
  
  const handleChange = (e) => {
    setContactDetails((prev) => ({ ...prev, [e.target.id]: e.target.value}));
  }

  const  handleSubmit = async () => {
    
    if(!hasValues(contactDetails)){
        setContact((prev) => ({...prev, ['error']: 'Enter required fields'}))
        return;
    }

    setContact((prev) => ({...prev, ['loading']: true}))

    try{
        await axios.post("/contacts", contactDetails);
        setContact((prev) => ({...prev, ['loading']:false, ['error']: "Contact details submitted"}))
        setContactDetails((prev) => ({...prev, ['name']: '', ['email']: '', ['message']: ''}))
    }catch(err){
        setContact((prev) => ({...prev, ['loading']:false, ['error']: err.response.data.message}))
        return
    }
}
  
  return (
    <div className="contact">
        <Navbar type={"contact"} />
        <div className="container">
            <p>{ contact.error? contact.error : "" }</p>
            <div className="content">
                <div className="left-side">
                    <div className="address details">
                        <i className='bx bxs-map'></i>
                        <div className="topic">Address</div>
                        <div className="text-one">Manyanja Road</div>
                        <div className="text-two">UpperHill, NRB, Kenya</div>
                    </div>
                    <div className="phone details">
                        <i className='bx bx-phone'></i>
                        <div className="topic">Phone</div>
                        <div className="text-one">+254 707 0000</div>
                        <div className="text-two">+254 606 0000</div>
                    </div>
                    <div className="email details">
                        <i className='bx bx-envelope' ></i>
                        <div className="topic">Email</div>
                        <div className="text-one">elationcleaners@gmail.com</div>
                        <div className="text-two">info@elationcleaners.com</div>
                    </div>
                </div>
                <div className="right-side">
                    <div className="topic-text">Send us a message</div>
                    <p>If you have any work from me or any types of quries related to my tutorial, you can send me message from here. It's my pleasure to help you.</p>
                    <form action="#">
                        <div className="input-box">
                        <input type="text" placeholder="Enter your name" id="name" value={contactDetails.name} onChange={handleChange}/>
                        </div>
                        <div className="input-box">
                        <input type="text" placeholder="Enter your email" id="email" value={contactDetails.email} onChange={handleChange}/>
                        </div>
                        <div className="input-box message-box">
                        <textarea placeholder="Enter your message" id="message" value={contactDetails.message} onChange={handleChange}></textarea>
                        </div>
                        <div className="button">
                            <input type="button" value="Send Now" onClick={(e) => handleSubmit()} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Contact