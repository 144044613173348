import { createContext, useEffect, useReducer } from "react";

const INITIAL_STATE = {
  place: JSON.parse(localStorage.getItem("search"))?.place || undefined,
  cleaning_type: JSON.parse(localStorage.getItem('search'))?.cleaning_type || undefined,
};

export const SearchContext = createContext(INITIAL_STATE);

const SearchReducer = (state, action) => {
  switch (action.type) {
    case "NEW_SEARCH":
      return action.payload;
    case "RESET_SEARCH":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const SearchContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SearchReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("search", JSON.stringify({place: state.place, cleaning_type: state.cleaning_type}));
  }, [state]);

  return (
    <SearchContext.Provider
      value={{
        place: state.place,
        cleaning_type: state.cleaning_type,
        dispatch,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
















