import { NavLink, useNavigate } from "react-router-dom"
import "./navbar.css"
import { useContext, useState } from "react"
import { AuthContext } from "../../contexts/AuthContext"

const Navbar = ({ type }) => {
  const  { user, dispatch } = useContext(AuthContext) 
  const navigate = useNavigate() 
  const [menuActive, setMenuActive] = useState(false)

  const logOut = () => {
    setMenuActive(false)
    dispatch({ type: "LOGOUT" })
  }

  const handleNavigate = (item) => {
    setMenuActive(false)
    navigate("/dashboard", { state: { item } });
  };

  return (
    <div className="navbar">
        <div className="navContainer">
            <NavLink to="/" className="navLink">
                <div className="navTitle">
                    <h1>Elation <span>Cleaners</span></h1>
                </div>
            </NavLink>
            <div className="navLinks">
                <a href="/"><span className={type === "home"?"active":""}>Home</span></a>
                <a href="/services"><span className={type === "services"?"active":""}>Services</span></a>
                <a href="/contact"><span className={type === "contact"?"active":""}>Contact Us</span></a>
                <a href="/about"><span className={type === "about"?"active":""}>About Us</span></a>
            </div>
            {user? 
            <div className="navbarMenuProfile" onClick={() => setMenuActive(!menuActive)}>
                <span className="title">{`${user.firstname} ${user.lastname}`} <i class='bx bxs-chevron-down'></i></span>
                <ul className={menuActive?"menu":"menu no-show"}>
                    <li onClick={(e) => handleNavigate({ mainTab : 1, subTab: 1 })}>Account</li>
                    <li onClick={(e) => handleNavigate({ mainTab : 2, subTab: 1 })}>Favorite</li>
                    <li onClick={(e) => handleNavigate({ mainTab : 3, subTab: 1 })}>Bookings</li>
                    <li onClick={logOut}>LogOut</li>
                </ul>
            </div> :
            <div className="navbarMenu" onClick={() => navigate('/login')}>
                <span>Login</span>
                <i class='bx bx-menu'></i>
            </div>}
        </div>
    </div>
  )
}

export default Navbar