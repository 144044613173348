import { useState } from "react";
import useFetch from "../../hooks/useFetch"
import FeaturedCleaningOperatorCard from "../cards/featuredCleaningOperatorCard/FeaturedCleaningOperatorCard"
import "./featuredCleaningOperator.css"

const FeaturedCleaningOperator = () => {
  const [featuredOperatorIndex, setFeaturedOperatorIndex] = useState({min: 0, max: 3});   
  const { data, loading, error } = useFetch(`/services/recommended?limit=6`)  

  
  const handlePrev = () => {
    if(featuredOperatorIndex.min > 0){
        setFeaturedOperatorIndex((prev) => ({min: (prev.min - 1), max: (prev.max - 1)}))
    }
  }

  const handleNext = () => {
    if(featuredOperatorIndex.max >= 3 && featuredOperatorIndex.max < data.length){
        setFeaturedOperatorIndex((prev) => ({min: (prev.min + 1), max: (prev.max + 1)}))
    }
  } 

  return (
    <div className="featuredCleaningOperator">
        <div className="featuredCleaningOperatorContainer">
            <div className="featuredCleaningOperatorHeader">
                <span className="operatorTitle">Top Cleaning <span className="s1">Providers</span><sup><i class='bx bx-star'></i></sup></span>
            </div>
            <div className="featuredCleaningOperatorBody">
                <div className="featuredCleaningOperatorBodyItems">
                    {!loading && data?.slice(featuredOperatorIndex.min, featuredOperatorIndex.max).map((service, index) => (
                        <FeaturedCleaningOperatorCard operator={service} key={index} />
                    ))}
                </div>
                <div className="navItems">
                    <span onClick={data?.length > 0?(e) => handlePrev():null}><i class='bx bxs-chevron-left'></i></span>
                    <span onClick={data?.length > 0?(e) => handleNext():null}><i class='bx bxs-chevron-right'></i></span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FeaturedCleaningOperator