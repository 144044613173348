import { useNavigate } from "react-router-dom";
import "./featuredCityCard.css"
import { useContext } from "react";
import { SearchContext } from "../../../contexts/SearchContext";

const FeaturedCityCard = ({ city }) => {
  const navigate = useNavigate();
  const { dispatch }  = useContext(SearchContext);

  const handleClick = (place, cleaning_type) => {
    dispatch({ type: "NEW_SEARCH", payload: { place, cleaning_type } });
    navigate("/services");
  }

  return (
    <div className="featuredCityCard" onClick={() => handleClick(city.name, "")}>
        <div className="featuredCityCardContainer">
            <div className="featuredCityCardImg">
                <img src={`../../images/${city.img}`} alt={city.name} />
            </div>
            <div className="featuredCityCardImgCover"></div>
            <div className="featuredCityCardDetails">
                <span className="cityName"><i class='bx bx-map' ></i>{city.name}</span>
                <span className="count">{`${city.count} cleaning services`}</span>
            </div>
        </div>
    </div>
  )
}

export default FeaturedCityCard