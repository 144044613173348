import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/home/Home";
import Services from "./pages/services/Services";
import SingleService from "./pages/singleService/SingleService";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Booking from "./pages/booking/Booking";
import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";
import Dashboard from "./pages/dashboard/Dashboard";

function App() {
  return (
   <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/login" element={<Login />}/>
      <Route path="/register" element={<Register />}/>
      <Route path="/contact" element={<Contact />}/>
      <Route path="/about" element={<About />}/>
      <Route path="/services" element={<Services />} />
      <Route path="/services/:id" element={<SingleService />} />
      <Route path="/services/booking/:id" element={<Booking />} />
      <Route path="/dashboard" element={<Dashboard />} />
    </Routes>
   </BrowserRouter>
  );
}

export default App;
