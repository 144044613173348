import { useNavigate } from "react-router-dom"
import "./register.css"
import { useState } from "react";
import axios from "axios"
import { user } from "../../utils/register";
import { hasValues } from "../../utils/helperFunctions";

const Register = () => {
    const navigate = useNavigate();
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [confirmPassword, setConfirmPassword] = useState('');
    const [userDetails, setUserDetails] = useState(user)
    const [register, setRegister] = useState({ loading: false, error: null })

    const togglePassword = () => {
        if(passwordType==="password")
        {
            setPasswordType("text")
        return;
        }
        setPasswordType("password")
    }

    const toggleConfirmPassword = () => {
        if(confirmPasswordType==="password")
        {
            setConfirmPasswordType("text")
        return;
        }
        setConfirmPasswordType("password")
    }

    const handleChange = (e) => {
        if(e.target.id === 'confirmpassword'){
                setConfirmPassword(e.target.value)
            return;
        }
        setUserDetails((prev) => ({ ...prev, [e.target.id]: e.target.value}));
    }

    const handleSubmit = async () => {
        const newUserDetails = {
            'firstname': userDetails.firstname,
            'lastname': userDetails.lastname,
            'email': userDetails.email,
            'address': userDetails.address,
            'phonenumber': userDetails.phonenumber,
            'password': userDetails.password,
        };
        if(!hasValues(newUserDetails)){
            setRegister((prev) => ({...prev, ['error']: 'Enter required fields'}))
            return;
        }

        if(userDetails.password !== '' && userDetails.password === confirmPassword){
            setRegister((prev) => ({...prev, ['loading']: true}))
            try{
                const resp = await axios.post("/auth/register", userDetails);
                setRegister((prev) => ({...prev, ['loading']: false, ['error']: resp.data}))
                setUserDetails(user)
            }catch(err){
                setRegister((prev) => ({...prev, ['loading']:false, ['error']: err.response.data.message}))
            }
        }else{
            setRegister((prev) => ({...prev, ['error']: 'Password Incorrect'}))
            return;
        }
    } 

  return (
    <div className="register">
        <div className="registerContainer">
            <div className="registerContainerHeader">
                <span className="accountRegister" onClick={(e) => navigate(-1)}>
                    <i class='bx bx-arrow-back'></i>
                    BACK
                </span>
            </div>
            <div className="registerBody">
                <h3 className="app-title">Create your <br /> <span className="s1">Elation</span> <span className="s2">Cleaners</span> account</h3>
                <div className="registerBodyContainer">
                    <div className="registerFormItemError">
                        <p className="registerFormError">{register.error?register.error:""}</p>
                    </div>
                    <div className="registerForm">
                        <div className="registerFormItem">
                            <label>Firstname <sup>*</sup></label>
                            <input 
                                type="text" 
                                placeholder="Enter your firstname"
                                value={userDetails.firstname}
                                onChange={(e) => handleChange(e)}
                                id="firstname"
                            />
                        </div>
                        <div className="registerFormItem">
                            <label>Lastname <sup>*</sup></label>
                            <input 
                                type="text" 
                                placeholder="Enter your lastname"
                                value={userDetails.lastname}
                                onChange={(e) => handleChange(e)}
                                id="lastname"
                            />
                        </div>
                        <div className="registerFormItem">
                            <label>Email <sup>*</sup></label>
                            <input 
                                type="email" 
                                placeholder="Enter your email"
                                value={userDetails.email}
                                onChange={(e) => handleChange(e)}
                                id="email"
                            />
                        </div>
                        <div className="registerFormItem">
                            <label>Phone number <sup>*</sup></label>
                            <input 
                                type="phone" 
                                placeholder="Enter your phonenumber"
                                value={userDetails.phonenumber}
                                onChange={(e) => handleChange(e)}
                                id="phonenumber"
                            />
                        </div>
                        <div className="registerFormItem">
                            <label>Alt Phone number <sup>*</sup></label>
                            <input 
                                type="phone" 
                                placeholder="Enter alternative phone number"
                                value={userDetails.altphonenumber}
                                onChange={(e) => handleChange(e)}
                                id="altphonenumber"
                            />
                        </div>
                        <div className="registerFormItem">
                            <label>Address location <sup>*</sup></label>
                            <input 
                                type="location" 
                                placeholder="Enter your location i.e Nairobi"
                                value={userDetails.address}
                                onChange={(e) => handleChange(e)}
                                id="address"
                            />
                        </div>
                        <div className="registerFormItem">
                            <label>Password</label>
                            <div className="passwordField">
                                <input 
                                    type={passwordType} 
                                    id="password" 
                                    name="password" 
                                    value={userDetails.password}
                                    onChange={(e) => handleChange(e)}
                                    placeholder="Enter password" 
                                />
                                <span>
                                    <i onClick={togglePassword} class={passwordType==="password"?"bx bx-show":"bx bx-hide"} ></i>
                                </span>
                            </div>
                        </div>
                        <div className="registerFormItem">
                            <label>Confirm Password</label>
                            <div className="passwordField">
                                <input 
                                    type={confirmPasswordType} 
                                    id="confirmpassword" 
                                    name="confirmpassword" 
                                    placeholder="Confirm password" 
                                    value={confirmPassword}
                                    onChange={(e) => handleChange(e)}
                                />
                                <span>
                                    <i onClick={toggleConfirmPassword} class={confirmPasswordType==="password"?"bx bx-show":"bx bx-hide"} ></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="registerFormItemBtn" onClick={(e) => handleSubmit()}>
                        {register.loading?"Submitting...":"Submit"}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Register