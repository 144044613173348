import { useNavigate } from "react-router-dom";
import "./serviceCard.css"
import { createRatingStars, formatEmailAddress, formatPhoneNumber, getAverageRating } from "../../../utils/helperFunctions";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";

const ServiceCard = ({ service }) => {
    const  { user } = useContext(AuthContext)
  const navigate = useNavigate();
  const handleRequest = () => {
    if(!user){
      navigate("/login")
    }else{
      navigate(`/services/booking/${service?._id}`)
    }
  }
  return (
    <div className="serviceCard">
        <div className="serviceCardContainer">
            <div className="logo" onClick={() => navigate(`/services/${service._id}`)}>
                <div className="imgContainer">
                    <img src={service.logo} />
                </div>
            </div>
            <div className="details">
                <div className="serviceName" onClick={() => navigate(`/services/${service._id}`)}>{service.title}</div>
                <div className="ratingDetails">
                    <span className="rating">
                    {createRatingStars(getAverageRating(service.reviews)).map((rating, index) => (
                        <i className={rating} key={index}></i>
                    ))}
                    </span>
                    <span className="reviews">{`${service.reviews?.length} Reviews`}</span>
                </div>
                <div className="desc">
                    {service.desc}
                </div>
                <div className="serviceContacts">
                    <div className="serviceItem">
                        <span className="serviceItemType">Location</span>
                        <span className="serviceItemValue city">{service.location}</span>
                    </div>
                    <div className="serviceItem">
                        <span className="serviceItemType">Phone</span>
                        <span className="serviceItemValue">{formatPhoneNumber(`+254${service.phone}`)}</span>
                    </div>
                    <div className="serviceItem">
                        <span className="serviceItemType">Email</span>
                        <span className="serviceItemValue">{formatEmailAddress(service.email)}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <span className="callNow" onClick={() => handleRequest()}>
                    Request Service
                </span>
            </div>
        </div>
    </div>
  )
}

export default ServiceCard