import { useEffect, useState } from "react"
import "./bookingCard.css"
import { format, parseISO } from "date-fns"
import axios from "axios"
import { createRatingStars, getAverageRating } from "../../../utils/helperFunctions"

const BookingCard = ({ booking }) => {

  const [serviceTypes, setServiceTypes]  = useState([])

  useEffect(() => {
    const getServiceTypes = async () => {
        const allTypes  =  await Promise.all(
        booking?.cleaning_services?.map(async(serviceId) => {
              const res = await axios.get(`/categories/find/${serviceId}`)
              return res.data
            })
        )
        setServiceTypes(allTypes)
    }

    getServiceTypes()
  }, [booking])

  const handleBookingClick = async (id,sts) => {
    const res = await axios.put(`/bookings/${id}`, { status: sts });
  }

  return (
    <div className="bookingCard">
        <div className="bookingCardPeople">
            <div className="bookingCardListing">
                <div className="bookingCardListingImage">
                    <img src={booking?.Service?.logo} />
                </div>
                <div className="bookingCardListingDetails">
                    <span className="listingName">
                        {booking?.Service.title}
                    </span>
                    <div className="listingRating">
                        <span className="rating">
                        {createRatingStars(getAverageRating(booking?.Service.reviews)).map((rating, index) => (
                          <i className={rating} key={index}></i>
                        ))}
                        </span>
                        <span className="count">{getAverageRating(booking?.Service.reviews)}({`${booking?.Service.reviews.length} Reviews`})</span>
                    </div>
                </div>
            </div>
            <div className="bookingCardPerson">
                <div className="bookingCardPersonBody">
                    <div className="bookingCardPersonBodyItem">
                        <span className="itemTitle">Email :</span>
                        <span className="itemValue">{booking?.Service?.email}</span>
                    </div>
                    <div className="bookingCardPersonBodyItem">
                        <span className="itemTitle">Contact :</span>
                        <span className="itemValue">{`+254${booking?.Service?.phone}`}</span>
                    </div>
                    <div className="bookingCardPersonBodyItem">
                        <span className="itemTitle">Address :</span>
                        <span className="itemValue">{booking?.Service?.location}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="bookingCardDetails">
            <div className="bookingCardDuration">
                <span className="bookingCardDurationTitle">
                    Booked for
                </span>
                <div className="bookingCardDurationBody">
                    <span className="bookingCardDurationDays">
                        {`${booking.dates.length} Days`}
                    </span>
                    <div className="bookingCardDurationBodyItems">
                        <span className="bookingDurationItem">
                            <span className="itemTitle">Starting at : </span>
                            <span className="itemValue">{format(parseISO(new Date(Date.parse(booking.dates[0])).toISOString()), "MMMM dd, yyyy")}</span>
                        </span>
                        <span className="bookingDurationItem">
                            <span className="itemTitle">Ending at : </span>
                            <span className="itemValue">{format(parseISO(new Date(Date.parse(booking?.dates[booking?.dates.length-1])).toISOString()), "MMMM dd, yyyy")}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="bookingCardPayments">
                <span className="bookingCardPaymentTitle">
                    Services requested
                </span>
                <div className="bookingCardPaymentBody">
                    {serviceTypes.map((service, index) => (
                      <div className="serviceTypeItem">
                        <img src={service.img} />
                        <span>{service.category}</span>
                      </div>
                    ))}
                </div>
            </div>
        </div>
        <div className="bookingCardActions">
            {booking?.status === 'submitted'  || booking.status === 'cancelled' || booking?.status === "confirmed"?
            <span className="bookingCardActionItem confirm">
                Rebook
            </span>:<></>}
            {booking?.status === 'submitted' || booking?.status === "confirmed"?
            <span className="bookingCardActionItem reject" onClick={() => handleBookingClick(booking?._id,"cancelled")}>
                Cancel
            </span>:<></>}
            <span className="bookingCardActionItem status">
                {booking.status === 'submitted'?
                "Awaiting  Confirmation":
                booking.status === 'cancelled'?
                "Cancelled":
                booking.status === 'rejected'?
                "Rejected":
                booking.status === 'confirmed'?
                "Confirmed":
                ""
                }
            </span>
        </div>
    </div>
  )
}

export default BookingCard