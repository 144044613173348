import { useContext, useState } from "react"
import "./dashboardBooking.css"
import { AuthContext } from "../../contexts/AuthContext"
import useFetch from "../../hooks/useFetch"
import BookingCard from "../cards/bookingCard/BookingCard"
import Paginate from "../paginate/Paginate"

const DashboardBooking = ({ activeItem }) => {
  const  { user } = useContext(AuthContext)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(1)
  const [bookingStatus, setBookingStatus] = useState('');
  const { data, loading, error } = useFetch(`/bookings/userpaginated?owner=${user?._id}&status=${bookingStatus}&page=${page}&limit=${limit}`)  

  const handlePageClick = (e) => {
    setPage(e.selected + 1)
  }

  const handleBookingStatus = (e) => {
    setBookingStatus(e.target.value)
  }

  console.log(data)

  return (
    <div className={activeItem.mainTab == 3 && activeItem.subTab == 1?"dashboardBooking":"dashboardBooking no-show"}>
        <div className="dashboardBookingHeader">
            <span className="dashboardBookingTitle">
                Bookings
            </span>
            <div className="dashboardBookingHeaderBody">
                <div className="dashboardBookingItem input">
                    <input type="datetime-local" />
                    <span className="vDiv"></span>
                    <input type="datetime-local" />
                </div>
                <div className="dashboardBookingItem">
                    <select
                        onChange={(e) => handleBookingStatus(e)}
                        value={bookingStatus}
                    >
                        <option value=''><input type="checkbox" /><span>Booking Status</span></option>
                        <option value={'submitted'}><input type="checkbox" /><span>Awaiting Confirmation</span></option>
                        <option value={'confirmed'}><input type="checkbox" /><span>Confirmed</span></option>
                        <option value={'cancelled'}><input type="checkbox" /><span>Cancelled</span></option>
                        <option value={'rejected'}><input type="checkbox" /><span>Rejected</span></option>
                    </select>
                </div>
            </div>
        </div>
        <div className="dashboardBookingBody">
            {data?.result?.length > 0?
            data?.result?.map((booking, index) => (
                <BookingCard booking={booking} key={index} />
            )):<p>No Bookings</p>}
        </div>
        <Paginate handlePageClick={handlePageClick} pageCount={data?.pageCount} />
    </div>
  )
}

export default DashboardBooking