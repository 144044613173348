import { useLocation, useNavigate } from "react-router-dom"
import "./singleServiceListing.css"
import useFetch from "../../hooks/useFetch"
import { useContext, useEffect, useState } from "react"
import axios from "axios"
import Rating from 'react-rating'
import { formatEmailAddress, formatPhoneNumber, getAverageRating } from "../../utils/helperFunctions"
import ReviewCard from "../cards/reviewCard/ReviewCard"
import { AuthContext } from "../../contexts/AuthContext"

const SingleServiceListing = () => {

  const  { user } = useContext(AuthContext) 
  const navigate = useNavigate()

  const location = useLocation()
  const id = location.pathname.split("/")[2]
  const [serviceTypes, setServiceTypes]  = useState([])
  const [rate, setRate] = useState(0)
  const [message, setMessage] = useState('')
  const [submit, setSubmit] = useState({ message: "", submitting: false })
  const { data, loading, error } = useFetch(`/services/find/${id}`)
  const [isFav, setIsFav] = useState(false)
  const [favorite, setFavorite] = useState([])

  useEffect(() => {
    const getServiceTypes = async () => {
      if(!loading && !error && data.title !== undefined){
        const allTypes  =  await Promise.all(
          data?.cleaning_services?.map(async(serviceId) => {
                const res = await axios.get(`/categories/find/${serviceId}`)
                return res.data
              })
          )
          setServiceTypes(allTypes)
      }
    }

    getServiceTypes()
  }, [data, id])

  const handleRateChange = (rate) => {
    setRate(rate)
  }

  const handleChange = (e) => {
    setMessage(e.target.value)
  }

  const handleSubmit = async () => {
    if(!user){
      navigate("/login")
    }else{
      setSubmit((prev) => ({...prev, ['submitting']: true }))
      const res = await axios.put(`/services/updateReview/${data?._id}`, 
                    { review: { rating: rate, userId: user._id, desc: message }}
                  )
      if(res){
        setSubmit((prev) => ({...prev, ['message']: res.data, ['submitting']: false }))
      }
    }
  }

  const handleRequest = () => {
    if(!user){
      navigate("/login")
    }else{
      navigate(`/services/booking/${data?._id}`)
    }
  }

  useEffect(() => {

    getFav()
  }, [user, data])

  const getFav = async () => {
    const fav = await axios.get(`/favorites?user=${user?._id}&service=${data?._id}`)
    if(fav.data.length > 0){
      setFavorite(fav.data)
      setIsFav(true)
    }else{
      setIsFav(false)
    }
  }

  console.log(favorite)

  const handleFavorite = async () => {
    if(isFav){
      if(user){
        await axios.delete(`/favorites/${favorite[0]?._id}`)
        setIsFav(false)
      }else{
        navigate("/login")
      }
    }else{
      if(user){
        const newFav = {
          userId: user?._id,
          serviceId: data?._id
        }
        await axios.post(`/favorites`, newFav)
        setIsFav(true)
      }else{
        navigate("/login")
      }
    }
  }

  return (
    <div className="singleServiceListing">
        <div className="singleServiceListingContainer">
            <div className="singleServiceListingBody">
              <div className="singleServiceListingImage">
                <div className="logo">
                  <img src={data?.logo} />
                </div>
              </div>
              <div className="singleServiceListingDetails">
                <div className="singleServiceListingHeader">
                  <div className="singleServiceListingHeaderDetails">
                    <div className="location">
                      <i class='bx bxs-map'></i>
                      <span className="locationTitle">{data?.location}</span>
                    </div>
                    <span className="title">{data?.title}</span>
                  </div>
                  <div className="singleServiceListingHeaderButtons">
                    <span className={isFav?"bookFav active":"bookFav"} onClick={() => handleFavorite()}>
                      <i class='bx bxs-heart-circle'></i>
                    </span>
                    <span className="bookService" onClick={() => handleRequest()}>Request Service</span>
                  </div>
                </div>
                <div className="desc">
                  <p>{data?.desc}</p>
                </div>
                <div className="serviceContactDetails">
                  <div className="serviceItems">
                    <div className="serviceContactIcon">
                      <i class='bx bxs-phone'></i>
                      <span>Contact phone</span>
                    </div>
                    <div className="serviceContactIcon">
                      <i class='bx bxs-envelope' ></i>
                      <span>Email</span>
                    </div>
                    <div className="serviceContactIcon">
                      <i class='bx bxs-map'></i>
                      <span>Location</span>
                    </div>
                  </div>
                  <div className="serviceItemsDetails">
                    <span>{formatPhoneNumber(`+254${data?.phone}`)}</span>
                    <span>{formatEmailAddress(`${data?.email}`)}</span>
                    <span className="loc">{`${data?.location}`}</span>
                  </div>
                </div>
                <div className="serviceTypes">
                    <div className="title">Cleaning Services</div>
                    {serviceTypes?.length > 0 && serviceTypes?.map((serviceType, index) => (
                    <div className="serviceTypeItem" key={index}>
                      <div className="logo">
                        <img src={serviceType?.img} />
                      </div>
                      <span>{serviceType?.category}</span>
                    </div>))}
                </div>
              </div>
            </div>
            <div className="separator">
              <div className="sepH"></div>
              <span>{`Reviews (${data?.reviews?.length}) `}</span>
              <div className="sepH"></div>
            </div>
            <div className="singleServiceListingReviews">
              <div className="singleServiceListingReviewsBody">
                {data?.reviews?.length > 0?<div className="reviews">
                  {data?.reviews?.map((review, index) => (
                    <ReviewCard review={review} key={index} />
                  ))}
                </div>:<div className="no-reviws">
                    <p>No Reviews</p>
                </div>}
              </div>
              <div className="singleServiceListingAddReview">
                <span className="title">Add Review</span>
                <small>Leave your review for {data?.title}</small>
                <div className="reviewForm">
                    <div className="reviewFormItem">
                      <label>Rate</label>
                      <Rating
                        className="rating"
                        emptySymbol={<i class='bx bx-star'></i>}
                        fullSymbol={[1,2,3,4,5].map(n => <i class='bx bxs-star'>{n}</i>)}
                        onChange={(rate) => handleRateChange(rate)}
                      />
                    </div>
                    <div className="reviewFormItem">
                      <label>Message</label>
                      <textarea cols={4} placeholder="Leave a message" onChange={(e) => handleChange(e)}></textarea>
                    </div>
                    <span className="reviewBtn" onClick={() => handleSubmit()}>{submit.submitting?"Submitting":"Send"}</span>
                    <p>{submit.message}</p>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default SingleServiceListing