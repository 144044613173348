import Footer from "../../components/footer/Footer"
import Navbar from "../../components/navbar/Navbar"
import SingleServiceListing from "../../components/singleServiceListing/SingleServiceListing"
import "./singleService.css"

const SingleService = () => {
  return (
    <div className="singleService">
        <Navbar type={'services'} />
        <div className="singleServiceContainer">
            <SingleServiceListing />
        </div>
        <Footer />
    </div>
  )
}

export default SingleService