export const user = {
    'firstname': '',
    'lastname': '',
    'email': '',
    'address': '',
    'phonenumber': '',
    'altphonenumber': '',
    'photo': '',
    'password': '',
    'isAdmin': false
}