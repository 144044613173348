import Footer from "../../components/footer/Footer"
import Navbar from "../../components/navbar/Navbar"
import ServicesSearch from "../../components/servicesSearch/ServicesSearch"
import "./services.css"

const Services = () => {
  return (
    <div className="services">
        <Navbar type={'services'} />
        <div className="servicesContainer">
            <ServicesSearch />
        </div>
        <Footer />
    </div>
  )
}

export default Services