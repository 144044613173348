import { useNavigate } from "react-router-dom";
import "./featuredCleaningCategoriesCard.css"
import { useContext } from "react";
import { SearchContext } from "../../../contexts/SearchContext";

const FeaturedCleaningCategoriesCard = ({ cleaningCategory }) => {
  const navigate = useNavigate();
  const { dispatch }  = useContext(SearchContext);

  const handleClick = (place, cleaning_type) => {
    dispatch({ type: "NEW_SEARCH", payload: { place, cleaning_type } });
    navigate("/services");
  }

  return (
    <div className="featuredCleaningCategoriesCard" onClick={() => handleClick("", cleaningCategory._id)}>
        <div className="featuredCleaningCategoriesImg">
            <img src={cleaningCategory.img} />
        </div>
        <div className="featuredCleaningCategoriesDetails">
            <span className="cleaningTitle">{cleaningCategory.category}</span>
            <p className="cleaningDesc">{cleaningCategory.desc}</p>
        </div>
    </div>
  )
}

export default FeaturedCleaningCategoriesCard