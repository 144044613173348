export const getAverageRating = (reviews) => {
    if(reviews.length > 0){
        let total = 0;
        for(let review of reviews){
            total = parseInt(total) + parseInt(review.rating)
        }
        return parseInt(total) / parseInt(reviews.length)
    }else{
        return 0
    }
}

export const fileToDataURL = (file) =>  {
    var reader = new FileReader()
    return new Promise(function (resolve, reject) {
      reader.onload = function (event) {
        resolve({
            file: file,
            imgPreview: event.target.result
        })
      }
      reader.readAsDataURL(file)
    })
} 

export const createRatingStars = (count) => {
    if (Math.floor(count) == 0){
       return [
            'bx bx-star',
            'bx bx-star',
            'bx bx-star',
            'bx bx-star',
            'bx bx-star'
        ];
    }

    if (Math.floor(count) == 1){
        return [
            'bx bxs-star',
            'bx bx-star',
            'bx bx-star',
            'bx bx-star',
            'bx bx-star'
        ];
    }

    if (Math.floor(count) == 2){
        return [
            'bx bxs-star',
            'bx bxs-star',
            'bx bx-star',
            'bx bx-star',
            'bx bx-star'
        ];
    }
    
    if (Math.floor(count) == 3){
        return [
            'bx bxs-star',
            'bx bxs-star',
            'bx bxs-star',
            'bx bx-star',
            'bx bx-star'
        ];
    }

    if (Math.floor(count) == 4){
        return [
            'bx bxs-star',
            'bx bxs-star',
            'bx bxs-star',
            'bx bxs-star',
            'bx bx-star'
        ];
    }

    if (Math.floor(count) == 5){
        return [
            'bx bxs-star',
            'bx bxs-star',
            'bx bxs-star',
            'bx bxs-star',
            'bx bxs-star'
        ];
    }
}

export const formatPhoneNumber = (phone) => {
    let prefixLength = 4;
    let suffixLength = 3;

    let prefix  = phone.substring(0, prefixLength);
    let suffix  = phone.slice(-suffixLength);
    let nbStars = phone.length - (prefixLength + suffixLength);

    let formattedPhone = prefix + "*".repeat(nbStars) + suffix;

    return formattedPhone;
}

export const formatEmailAddress = (s) => {
    var i = s.indexOf('@');
    var startIndex = i * .2 | 0;
    var endIndex   = i * .9 | 0;
    return s.slice(0, startIndex) +
            s.slice(startIndex, endIndex).replace(/./g, '*') +
            s.slice(endIndex);
}

export const getDatesInRange = (startDate, endDate) => {
    const start = new Date(startDate)
    const end = new Date(endDate)

    const date = new Date(start.getTime())

    const dates = []

    while(date <= end){
        dates.push(new Date(date).getTime())
        date.setDate(date.getDate() + 1)
    }

    return dates
}

export const hasValues = (obj) => Object.values(obj).every(v => v !== null && typeof v !== "undefined" && v !== '' && v != 0);