import { useNavigate } from "react-router-dom";
import "./featuredCleaningOperatorCard.css"
import { createRatingStars, getAverageRating } from "../../../utils/helperFunctions";

const FeaturedCleaningOperatorCard = ({ operator }) => {
  const navigate = useNavigate();
  return (
    <div className="featuredCleaningOperatorCard" onClick={() => navigate(`/services/${operator._id}`)}>
      <div className="featuredCleaningOperatorCardContainer">
        <div className="featuredCleaningOperatorLogo">
            <img src={operator.logo} />
        </div>
        <div className="featuredCleaningOperatorDetails">
          <span className="operatorDetailsTitle">
              {operator.title}
          </span>
          <div className="operatorDetailsRating">
            <span className="count">{operator.ratingavg?Math.floor(operator.ratingavg):0}</span>
            <span className="rating">
              {createRatingStars(getAverageRating(operator?.reviews)).map((rating, index) => (
                <i className={rating} key={index}></i>
              ))}
            </span>
            <span className="reviews">{`${operator.reviews?.length} Reviews`}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedCleaningOperatorCard