import { useLocation, useNavigate } from "react-router-dom"
import Navbar from "../../components/navbar/Navbar"
import "./booking.css"
import useFetch from "../../hooks/useFetch"
import Footer from "../../components/footer/Footer"
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRange} from "react-date-range"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../contexts/AuthContext"
import axios from "axios"
import { getDatesInRange } from "../../utils/helperFunctions"

const Booking = () => {
  const  { user, dispatch } = useContext(AuthContext) 
  const navigate = useNavigate()
  const location = useLocation()
  const id = location.pathname.split("/")[3] 
  const { data, loading, error } = useFetch(`/services/find/${id}`)
  const [dates, setDates] = useState([{startDate: new Date(), endDate: new Date(), key: "selection"}])
  const [bookingAddressOption, setBookingAddressOption] = useState(1) 
  const [serviceTypes, setServiceTypes]  = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [saving, setSaving] = useState({ loading: false, error: null })
  const [info, setInfo] = useState({
    location: '',
    desc: ''
  })
  const [ booking, setBooking ] = useState({
    userId: '',
    serviceId: '',
    dates: [],
    location: '',
    cleaning_services: [],
    status: 'submitted',
    desc: ''
  })

  const handleBookingOption = (e) => {
    setBookingAddressOption(e.target.value)
  }

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  useEffect(() => {
    const getServiceTypes = async () => {
      if(!loading && !error && data.title !== undefined){
        const allTypes  =  await Promise.all(
          data?.cleaning_services?.map(async(serviceId) => {
                const res = await axios.get(`/categories/find/${serviceId}`)
                return res.data
              })
          )
          setServiceTypes(allTypes)
      }
    }

    getServiceTypes()
  }, [data, id])

  const handleSelectedCategory = (e) => {
    const checked = e.target.checked
    const value = e.target.value
    setSelectedCategories(
        checked
          ? [...selectedCategories, value]
          : selectedCategories.filter((item) => item !== value)
    )
  }

  const handleSubmitBooking = async () => {
    const newBooking = {
        ...booking,
        userId: user?._id,
        serviceId: id,
        dates: getDatesInRange(dates[0].startDate, dates[0].endDate),
        location: bookingAddressOption == 1?user.address: info.location,
        cleaning_services: selectedCategories,
        desc: info.desc
    }

    if(selectedCategories.length == 0){
        setSaving((prev) => ({...prev, ['error']: "Select at least one service"}))
        return
    }

    try{
        const resp = await axios.post("/bookings", newBooking);
        if(resp.data){
            setSaving((prev) => ({...prev, ['loading']:false, ['error']: 'A new booking has been made'}))
        }
    }catch(err){
        setSaving((prev) => ({...prev, ['loading']:false, ['error']: err.response.data.message}))
        return
    }
  }

  return (
    <div className="booking">
        <Navbar type={'services'} />
        <div className="bookingContainer">
            <p className="err">{saving.error?saving.error:""}</p>
            <div className="bookingCardContainer">
                <div className="bookingCardContainerHead">
                    <span className="title">{`Book ${data.title}?`}</span>
                    <span className="close" onClick={() => navigate(-1)}><i class='bx bx-x'></i></span>
                </div>
                <div className="bookingCardContainerBody">
                    <div className="bookingCalendar">
                        <label>Select date</label>
                        <DateRange 
                            editableDateInputs={false}
                            showDateDisplay={false}
                            onChange={item=>setDates([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={dates} 
                            rangeColors={['#007cfa', '#3ecf8e', '#fed14c']}
                            className="startDate"
                        />
                    </div>
                    <div className="bookingDetails">
                        <div className="bookingCardDetailsItem">
                            <span className="title">Set my address</span>
                            <div className="bookingLocation">
                                <div className="bookingLocationItem">
                                    <input 
                                        type="checkbox"  
                                        value={1} 
                                        checked={bookingAddressOption == 1}
                                        onChange={handleBookingOption}
                                    />
                                    <span className={bookingAddressOption == 1?"active":""}>Use my address</span>
                                </div>
                                <div className="bookingLocationItem">
                                    <input 
                                        type="checkbox" 
                                        value={2} 
                                        checked={bookingAddressOption == 2} 
                                        onChange={handleBookingOption}
                                    />
                                    <span className={bookingAddressOption == 2?"active":""}>Use different address</span>
                                </div>
                            </div>
                            <input 
                                type="text" 
                                className="locationInput" 
                                readOnly={bookingAddressOption == 1?true:false}
                                value={bookingAddressOption == 1?user?.address:info.location}
                                id="location"
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <div className="bookingCardDetailsItem">
                            <span className="title">Select cleaning services</span>
                            <div className="bookingCleaningTypes">
                                {serviceTypes.length > 0 && serviceTypes?.map((serviceType, index) => (
                                <div className="bookingCleaningTypesItem" key={index}>
                                    <input 
                                        type="checkbox"
                                        value={serviceType._id}
                                        onChange={(e) => handleSelectedCategory(e)}
                                    />
                                    <span className="type">{serviceType?.category}</span>
                                </div>
                                ))}
                            </div>
                        </div>
                        <div className="bookingCardDetailsItem">
                            <span className="title">Leave a note</span>
                            <textarea id="desc" cols={6} onChange={(e) => handleChange(e)}>

                            </textarea>
                        </div>
                        <div className="bookingCardDetailsItem btn">
                            <span className="btnSubmit" onClick={() => handleSubmitBooking()}>{saving.loading?"Submitting":"Submit"}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Booking