export const citiesList = [
    {
        name: "Nairobi",
        id: "nairobi",
        img: "nairobi.png"
    },
    {
        name: "Kisumu",
        id: "kisumu",
        img: "kisumu.jpg"
    },
    {
        name: "Mombasa",
        id: "mombasa",
        img: "mombasa.jpg"
    },
    {
        name: "Nakuru",
        id: "nakuru",
        img: "nakuru.png"
    },
    {
        name: "Machakos",
        id: "machakos",
        img: "machakos.jpg"
    },
    {
        name: "Eldoret",
        id: "eldoret",
        img: "eldoret.webp"
    },
    {
        name: "Kiambu",
        id: "kiambu",
        img: "kiambu.jpg"
    }
];