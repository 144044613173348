import { useContext, useRef, useState } from "react"
import "./dashboardProfile.css"
import { AuthContext } from "../../contexts/AuthContext"
import { fileToDataURL } from "../../utils/helperFunctions"
import axios from "axios"

const DashboardProfile = ({ activeItem }) => {
  const  { user } = useContext(AuthContext)  
  const [openPhotoModalCard, setOpenPhotoModalCard] = useState(false)
  const [uploadedPhotoFile, setUploadedPhotoFile] = useState(null)

  const hiddenFileInput = useRef(null);

  const handleResetPhotoUpload = () => {
    hiddenFileInput.current.value = ""
  }

  const handlePhotoUpload = async () => {
    if(uploadedPhotoFile){
        const data = new FormData();
        data.append("file", uploadedPhotoFile.file);
        data.append("upload_preset", "upload");

        try {
            const uploadRes = await axios.post(
                "https://api.cloudinary.com/v1_1/elation/image/upload",
                data
            );
            const { url } = uploadRes.data;
            const newImg = {
                photo: url,
            };
            await axios.put(`/users/${user._id}`, newImg);
            setUploadedPhotoFile(null)
        } catch (err) {
            console.log(err);
        }
    }
  }

  const handleClickPhotoUpload = () => {
    hiddenFileInput.current.click();
  }

  const handleSelectPhotoFile = async (e) => {
    const  photoFile = await fileToDataURL(e.target.files[0])
    if(photoFile){
      setUploadedPhotoFile(photoFile)
      setOpenPhotoModalCard(true)
    }
  }

  return (
    <div className={activeItem.mainTab == 1 && activeItem.subTab == 1?"dashboardAccount":"dashboardAccount no-show"}>
        <div className="dashboardAccountHeader">
            <h3>Account</h3>
        </div>
        <div className="dashboardAccountBody">
            <div className="accountDetails">
                <div className="accountDetailsEdit">
                    <span className="editBtn">Edit</span>
                </div>
                <div className="accountDetailsPhoto">
                    <div className="photo">
                        <img src={uploadedPhotoFile?uploadedPhotoFile.imgPreview:user?.photo !== ""?user?.photo:"../../images/profile.jpg"} />
                        <span 
                        onClick={()=>handleClickPhotoUpload()}
                        >
                        <i class='bx bx-camera'></i>
                        <input 
                            type="file" 
                            accept="images/*" 
                            name="profilephoto" 
                            id="profilephoto" 
                            ref={hiddenFileInput}
                            multiple={false}
                            onChange={(e) => handleSelectPhotoFile(e)}
                        />
                        </span>
                    </div>
                    {uploadedPhotoFile &&  <span className="uploadPhoto" onClick={() => handlePhotoUpload()}>
                        Upload
                    </span>}
                </div>
                <div className="accountDetailsInfo">
                    <div className="accountDetailsInfoItem">
                        <label>Firstname</label>
                        <span>{user?.firstname}</span>
                    </div>
                    <div className="accountDetailsInfoItem">
                        <label>Lastname</label>
                        <span>{user?.lastname}</span>
                    </div>
                    <div className="accountDetailsInfoItem">
                        <label>Email</label>
                        <span>{user?.email}</span>
                    </div>
                    <div className="accountDetailsInfoItem">
                        <label>Phonenumber</label>
                        <span>{`+254${user?.phonenumber}`}</span>
                    </div>
                    <div className="accountDetailsInfoItem">
                        <label>Alt Number</label>
                        <span>{user?.altphonenumber?`+254${user?.altphonenumber}`:"No data"}</span>
                    </div>
                    <div className="accountDetailsInfoItem">
                        <label>Address</label>
                        <span>{user?.address}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DashboardProfile