import { useEffect, useRef, useState } from "react"
import { Range } from "react-range";
import "./servicesSearch.css"
import useFetch from "../../hooks/useFetch";
import ServiceCard from "../cards/serviceCard/ServiceCard";
import { SearchContext } from "../../contexts/SearchContext"
import axios from "axios";
import { useContext } from "react";
import Paginate from "../paginate/Paginate";

const ServicesSearch = () => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(2)  
  const { place, cleaning_type, dispatch } = useContext(SearchContext)  
  const cityInputRef = useRef(null);
  const [price, setPrice] = useState({values: [0, 100000]})
  const [rating, setRating] = useState();
  const [categories, setCategories] = useState([])
  const [city, setCity] = useState(place !== undefined && place !== ""?place.toLowerCase():'')
  const [selectedCategories, setSelectedCategories] = useState(cleaning_type?[cleaning_type]:[])
  const { data, loading, error } = useFetch(rating > 0?`/services/paginate?city=${city}&categories=${selectedCategories}&review=${rating}&page=${page}&limit=${limit}`:`/services/paginate?city=${city}&categories=${selectedCategories}&page=${page}&limit=${limit}`)  

  const handlePageClick = (e) => {
    setPage(e.selected + 1)
  }

  const handleCity = (el) => {
    setCity(el.toLowerCase())
  };

  const handleSearch = () => {
    handleCity(cityInputRef.current.value)
  }

  const handleSetRating = (e) => {
    if(rating == e.target.value){
        setRating(0)
    }else{
        setRating(e.target.value)
    }
  }

  const handleSelectedCategory = (e) => {
    const checked = e.target.checked
    const value = e.target.value
    setSelectedCategories(
        checked
          ? [...selectedCategories, value]
          : selectedCategories.filter((item) => item !== value)
    )
  }

  useEffect(() => {
    const getCleaningTypes = async () => {
        const allCategories =  await axios.get(`/categories`)
        setCategories(allCategories.data)
    }

    getCleaningTypes()
  }, []);

  useEffect(() => {
    if(place !== undefined && place !== ''){
        setCity(place.toLowerCase())
    }
  }, [place])

  const handleResetSearch = () => {
    setCity('')
    setSelectedCategories([])
    setRating(0)
    dispatch({ type: "RESET_SEARCH" });
  }

  const checkCategoryExists = (categoryId) => {
    return selectedCategories?.includes(categoryId)
  }

  console.log(data)

  return (
    <div className='servicesSearch'>
        <div className="servicesSearchContainer">
            <div className="servicesSearchHeader">
                <span className="title">Find your <br /><span className="s1">best</span> <span className="s2">cleaners</span> here</span>
                <p>Discover the easiest way to find top-rated cleaning services <br /> in your area. 
                Our website connects you with professional cleaners, <br /> making it effortless to maintain 
                a spotless and inviting home or workplace.</p>
            </div>
            <div className="servicesSearchBody">
                <div className="servicesSearchFilters">
                    <div className="servicesSearchFiltersItem priceContainer">
                        <span className="title">Your price</span>
                        <span className="price">{`Min ${price.values[0]} - Max ${price.values[1]}`}</span>
                        <Range 
                            key={6666}
                            step={1}
                            min={0}
                            max={100000}
                            values={price.values}
                            onChange={(values) => setPrice({values})}
                            renderTrack={({ props, children }) => (
                                <div
                                className="minValuesContainer"
                                {...props}
                                style={{
                                    ...props.style,
                                }}
                                >
                                {children}
                                </div>
                            )}
                            renderThumb={({ props }) => (
                                <div 
                                className="maxValuesContainer"
                                {...props}
                                style={{
                                    ...props.style,
                                }}
                                />
                            )}
                        />
                    </div>
                    <div className="servicesSearchFiltersItem">
                        <span className="title">Cleaning Types</span>
                        <div className="cleaningTypesContainer">
                            {categories?.map((category, index) => (
                            <div className="cleaningTypeItem" key={index}>
                                <input 
                                    type="checkbox" 
                                    value={category._id} 
                                    onChange={(e) => handleSelectedCategory(e)}
                                    checked={checkCategoryExists(category._id)}
                                />
                                <span className="cleaningType">{category.category}</span>
                            </div>))}
                        </div>
                    </div>
                    <div className="servicesSearchFiltersItem">
                        <span className="title">Rating</span>
                        <span className="ratingDesc">Includes star and other ratings</span>
                        <div className="ratingContainer">
                            <div className="ratingContainerItem">
                                <input type="checkbox" 
                                    value={5}
                                    onChange={(e) => handleSetRating(e)}
                                    checked={rating == 5}
                                />
                                <span className="ratingStars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </span>
                                <span className="ratingCount">
                                    (5.0)
                                </span>
                            </div>
                            <div className="ratingContainerItem">
                                <input type="checkbox" 
                                    value={4}
                                    onChange={(e) => handleSetRating(e)}
                                    checked={rating == 4}
                                />
                                <span className="ratingStars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bx-star' ></i>
                                </span>
                                <span className="ratingCount">
                                    (4.0+)
                                </span>
                            </div>
                            <div className="ratingContainerItem">
                                <input type="checkbox" 
                                    value={3}
                                    onChange={(e) => handleSetRating(e)}
                                    checked={rating == 3}
                                />
                                <span className="ratingStars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bx-star' ></i>
                                    <i class='bx bx-star' ></i>
                                </span>
                                <span className="ratingCount">
                                    (3.0+)
                                </span>
                            </div>
                            <div className="ratingContainerItem">
                                <input type="checkbox" 
                                    value={2}
                                    onChange={(e) => handleSetRating(e)}
                                    checked={rating == 2}
                                />
                                <span className="ratingStars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bx-star' ></i>
                                    <i class='bx bx-star' ></i>
                                    <i class='bx bx-star' ></i>
                                </span>
                                <span className="ratingCount">
                                    (2.0+) 
                                </span>
                            </div>
                            <div className="ratingContainerItem">
                                <input type="checkbox" 
                                    value={1}
                                    onChange={(e) => handleSetRating(e)}
                                    checked={rating == 1}
                                />
                                <span className="ratingStars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bx-star' ></i>
                                    <i class='bx bx-star' ></i>
                                    <i class='bx bx-star' ></i>
                                    <i class='bx bx-star' ></i>
                                </span>
                                <span className="ratingCount">
                                    (1.0+) 
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="servicesSearchBodyContainer">
                    <div className="servicesSearchBodyHeader">
                        <div className="servicesSearchBodyHeaderItem">
                            <i class='bx bx-map'></i>
                            <input type="text" ref={cityInputRef} placeholder="Enter your city" />
                        </div>
                        <div className="servicesBodyHeaderItem searchBtns">
                            <span className="servicesSearchBtn" onClick={() => handleSearch()}>
                                Search
                            </span>
                            {city !== undefined && city !== "" || 
                            selectedCategories.length > 0?
                            <span onClick={() => handleResetSearch()} className="resetSearch">Reset Search <i class='bx bx-x-circle'></i></span>:<></>
                            }
                        </div>
                    </div>
                    <div className="servicesSearchBodyResults">
                        {loading? <div className="loadingResults">
                            <p>Loading please wait ...</p>
                        </div>: !data?.result?.length > 0? <div className="emptyResults">
                            <p>No data found for your search</p>
                        </div> : data?.result?.map((service, index) => (
                            <ServiceCard service={service} key={index} />
                        ))}
                    </div>
                    <Paginate handlePageClick={handlePageClick} pageCount={data?.pageCount}/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ServicesSearch