import { SearchContext } from "../../contexts/SearchContext"
import useFetch from "../../hooks/useFetch"
import "./header.css"
import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"

const Header = () => {

  const navigate = useNavigate();
  const { dispatch }  = useContext(SearchContext);  

  const [place, setPlace] = useState('')
  const [cleaning_type, setCleaningType] = useState('');  
  const { data, loading, error } = useFetch(`/categories`) 

  const handleSearch = () => {
    dispatch({ type: "NEW_SEARCH", payload: { place, cleaning_type } });
    navigate("/services");
  };

  return (
    <div className="header" style={{  
        backgroundImage: "url(" + "/images/header-bg.png" + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
        <div className="headerContainer">
            <span className="headerIntro">Welcome to <span className="s1">Elation</span> <span className="s2">Cleaners</span></span>
            <span className="headerTitle">
                Expert <span className="s1">Cleaners & <br /></span> <span className="s2">Profession</span> Services
            </span>
            <div className="headerDesc">
                We provide best solutions for a clean environment<br />
                If you need any help in cleaning or maintaince.<br />
                Request an estimate.
            </div>
            <div className="headerSearch">
                <div className="headerSearchContainer">
                    <div className="headerSearchItem">
                        <i class='bx bx-briefcase'></i>
                        <select onChange={(e) => setCleaningType(e.target.value)}>
                            <option value={''}>Cleaning Type</option>
                            {!loading && data?.map((category) => (
                                <option value={category?._id}>{category?.category}</option>
                            ))};
                        </select>
                    </div>
                    <div className="headerSearchItem">
                        <i class='bx bx-map'></i>
                        <input type="text" placeholder="Enter your area" onChange={(e) => setPlace(e.target.value)} />
                    </div>
                    <span onClick={() => handleSearch()} className="headerBtn">Search</span>
                </div>
            </div>
        </div>
      </div>
  )
}

export default Header