import Header from "../../components/header/Header"
import Navbar from "../../components/navbar/Navbar"
import "./home.css"
import FeaturedCity from "../../components/featuredCity/FeaturedCity"
import FeaturedCleaningCategories from "../../components/featuredCleaningCategories/FeaturedCleaningCategories"
import FeaturedCleaningOperator from "../../components/featuredCleaningOperator/FeaturedCleaningOperator"
import Footer from "../../components/footer/Footer"

const home = () => {
  return (
    <div className="home">
      <Navbar  type={'home'}/>
      <Header />
      <div className="homeContainer">
        <FeaturedCity />
        <FeaturedCleaningCategories />
        <FeaturedCleaningOperator />
      </div>
      <Footer />
    </div>
  )
}

export default home